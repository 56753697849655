import * as React from "react";
import ContactForm from "../components/Contact/Contact";
import Layout from "../components/Layout";
import useAllClients from '../hooks/use-all-clients';
import useAllCampaigns from '../hooks/use-all-campaigns';
import useAllServiceSlider from '../hooks/use-all-services-slider';
import Seo from "../components/SEO";
import SimpleBanner from "../components/SimpleBanner/SimpleBanner";
import { ClientSlider } from "../components/ClientsSlider/ClientSlider";
import { TileSlider } from "../components/TileSlider/TileSlider";
import { transformTileContent } from '../utils/transform-data-functions';

const Contact = () => {

  const clients = useAllClients();
  const campaigns = transformTileContent(useAllCampaigns());
  const services = transformTileContent(useAllServiceSlider());

  return (
    <>
      <Seo title="Contact" />
      <Layout>
      <SimpleBanner title="Say Hello" introductionParagraph="Lets Connect. Our mission is to deliver top-tier comprehensive, strategic public relations and creative services with exceptional, technical-minded service to our client partners and their customers alike." hasDot={true} />
        <ContactForm />
        <TileSlider heading="Campaigns" intro="Our amazing team deliver incredible results across a variety of specialist services." tiles={campaigns} ctaText="View All" ctaLink="/campaigns" />
        <TileSlider heading="Our Services" tiles={services} />
        <ClientSlider heading="Clients." logos={clients} />
      </Layout>
    </>
  )
}

export default Contact
