import styled from "styled-components"

export const ContactStyles = styled.section`
  margin-bottom: 0px;

  .section {
    margin-bottom: 0px;
  }

  .container {
    position:relative;
    height: 100%;
    padding: 80px 30px 80px 30px;
    background-color: #F7F6F5;
    border-bottom: 80px solid rgb(239, 236, 234);

    p {
      max-width: 576px;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: var(--textBlack);
      margin-top: 0;
    }
  }

  @media (min-width: 769px) {
    .container {
      padding: 80px 112px 80px 112px;
    }
  }

  @media (max-width: 1024px) {
    padding: 0;
  }

  @media (min-width: 1480px) {
    padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
    .container {
      padding: 80px 112px 80px 112px;
    }
  }

  @media (min-width: 1680px) {
    .container {
      max-width: 1380px;
    }
  }

  form {
    max-width: 576px;
  }

  label {
    display: block;
    height: 28px;
    margin-top: 10px;
  }

  input {
    height: 53px;
    margin-bottom: 10px;
  }

  input,
  textarea {
    background-color: #fff;
    color: #333333;
    border: none;
    outline: 1px solid #332C28;
    border-radius: 0px;
    width: 100%;
    font-size: 18px;;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    padding: 8px 12px;
    transition: outline 0.3s ease;

    &:focus {
      outline: 2px solid var(--primary);
    }

    &::-webkit-input-placeholder {
      color: var(--inActive);
    }

    &::-moz-placeholder {
      color: var(--inActive);
    }

    &:-ms-input-placeholder {
      color: var(--inActive);
    }

    &:-moz-placeholder {
      color: var(--inActive);
    }
  }
  textarea {
    margin-bottom: var(--gap);
  }
`
