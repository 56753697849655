import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"

const ContactForm = () => {
  return (
    <ContactStyles className="section">
      <div className="container">
        <p>Please contact us today to explore partnership possibilities.</p>
        <form name="Website Enquiry" method="POST" data-netlify="true" netlify>
          <input type="hidden" name="form-name" value="Website Enquiry" />
          <label htmlFor="name">Name</label>
          <input placeholder="First name" type="text" name="name" />
          <label htmlFor="email">Email</label>
          <input placeholder="email@email.com" type="email" name="email" />
          <label htmlFor="company">Company</label>
          <input placeholder="Company name" type="company" name="company" />
          <label htmlFor="enquiry">Enquiry</label>
          <textarea
            name="enquiry"
            rows="5"
          ></textarea>
          <Button type="submit" text="SUBMIT" />
        </form>
      </div>
    </ContactStyles>
  )
}

export default ContactForm;
